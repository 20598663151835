// material-ui
import {useTheme} from '@mui/material/styles';

import Box from "@mui/material/Box";
import {Hearts} from "react-loader-spinner";
import Typography from "@mui/material/Typography";
import React from "react";


const Loader = ({topMargin=15, text=null}) => {
    const theme = useTheme();

    return (
       <Box
          sx={{
              mt: topMargin,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              height: '100%',
          }}
       >
           <Hearts
              height="80"
              width="80"
              color={theme.palette.secondary.main}
              ariaLabel="hearts-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
           />

          {text && (
               <Typography
                  variant={"h6"}
                  sx={{
                     color: theme.palette.text.secondary,
                     fontStyle: 'italic',
                     }}
               >
                  {text}
               </Typography>
          )}
       </Box>
    );
};

export default Loader;
