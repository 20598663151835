
export const BaseTheme = {


   spacing: (factor) => `${8 * factor}px`, // Custom spacing, change the '10' to your desired base unit

  typography: {
    fontFamily: '"Raleway", sans-serif',  // big, easy to read
     h1: {
        fontSize: '2.0rem',
        textAlign: 'center',
     },
     h2: {
        fontSize: '1.8rem',
        textAlign: 'center',
     },
     h3: {
        fontSize: '1.6rem',
        textAlign: 'center',
     },
     h35: {
        fontSize: '1.5rem',
        textAlign: 'center',
        lineHeight: 1.2
     },
     h4: {
        fontSize: '1.3rem',
        textAlign: 'center',
        lineHeight: 1.2
     },
     h5: {
        fontSize: '1.1rem',
        textAlign: 'center',
        lineHeight: 1.2
     },
     h6: {
        fontSize: '1.0rem',
        textAlign: 'center',
        lineHeight: 1.3

     },
     h7: {
        fontSize: '0.9rem',
        textAlign: 'center',
        lineHeight: 1.3
     },
     h8: {
        fontSize: '0.8rem',
        textAlign: 'center',
        lineHeight: 1.2
     },

     // custom typography's
     appTitle: {
       fontFamily: '"Annie Use Your Telescope", cursive',
       // fontFamily: 'Blackjack',
        fontSize: '1.7rem',
         // fontWeight: 'bold',
     },
     homeTitle: {
       fontFamily: '"Annie Use Your Telescope", cursive',
        fontSize: '2.2rem',
        textAlign: 'center',
        lineHeight: 1.3,
        // fontWeight: 'bold',
     },
     pageTitle: {
       fontFamily: '"Annie Use Your Telescope", cursive',
        fontSize: '1.7rem',

       // fontFamily:'"Comic Neue", cursive',
       // fontSize: '1.5rem',
       // fontFamily:'"Philosopher", sans-serif',

        textAlign: 'center',
        lineHeight: 1.3,
        fontWeight: 'bold',
     },
  },

  components: {

   // loading custom font - from MUI Typography page
    MuiCssBaseline: {
      // styleOverrides: `
      //   @font-face {
      //     font-family: 'Blackjack';
      //     font-style: normal;
      //     font-display: swap;
      //     font-weight: 400;
      //     src: local('Blackjack'),  url(${Blackjack}) format('truetype');
      //     unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      //   }
      // `,
    },
  },

   palette: {
      background: {
         button: '#f0f2f6',
         default: '#f8f8f9',
         white: '#ffffff',
         dark: '#2c2c2c',
         grey: '#c3c2c2'
      },
      chat: {
         player: '#abc7bc',
         npc: '#f4f7fc',
         text: '#130c0c'
      },
      navBar: {
         default: '#1c2938',
         icons: '#dad8d8',
         white: '#ffffff',
         border: '#dad8d8'
      },
      primary: {
         white: '#ffffff',
         // main: '#143c6b',
         // main: '#004c72',
         // light: '#455f79',
         // dark: '#151f2c',

         main: '#243243',
         // main: '#243243',
         light: '#6a85a5',
         dark: '#18232f',
          // good blue
         // main: '#24374d',
         // light: '#455f79',
         // dark: '#151f2c',
         grey: '#e1edfb',
         lightgrey: '#f6f6f6',
         contrastText: '#FFFFFF'
      },
      secondary: {


         main: '#37765d',
        // good turquoise
        //  main: '#018095',
         // main: '#b06106',
         light: '#89ac9e',
         background: '#ebf1ef',
         background2: '#d1e3df',
         dark: '#1a3f31',
         contrastText: '#FFFFFF'
      },
      tertiary: {
         main: '#ffd568',
         light: '#ffd568',
         dark: '#f6c56b',
         contrastText: '#FFFFFF'
      },

      success: {
         main: '#14b822',
         light: '#43c659',
         dark: '#0e8021',
         contrastText: '#FFFFFF'
      },
      info: {
         main: '#2196F3',
         light: '#64B6F7',
         dark: '#0B79D0',
         contrastText: '#FFFFFF'
      },
      warning: {
         main: '#ba8118',
         light: '#FFBF4C',
         dark: '#875d0e',
         contrastText: '#FFFFFF'
      },
      error: {
         main: '#D14343',
         light: '#DA6868',
         dark: '#744141',
         contrastText: '#FFFFFF'
      },
      text: {
         primary: '#1e1e1e',
         title: '#3c3c3c',
         secondary: '#666666',
         disabled: '#c3c2c2',
      },
      white: {
         pure: '#fff',
         offwhite: '#e1e1e1',

      },
      black: {
         pure: '#000',
      },
      grey: {
         light: '#666666',
         dark: '#3a3a3a'
      }
   }
}
