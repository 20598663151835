import React, { useState } from 'react';

import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme} from '@mui/material';

import './App.css';

import {BaseTheme} from "./theme/BaseTheme";
import Routes from './routes/Routes';

import ScrollTop from "./components/ScrollTop";

function App() {
    // @ts-ignore
    const theme = createTheme(BaseTheme);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <ScrollTop>
                <Routes/>
            </ScrollTop>
        </ThemeProvider>
    );
}

export default App;
