import {useState} from "react";
import * as React from "react";
import {lazy} from 'react';
import {Outlet, useRoutes, Navigate} from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';

// project import
import Loadable from '../components/Loadable';

const Home = Loadable(lazy(() => import('../pages/home/Home')));

export const homeList = [
   {
      path: "/",
      element: <Navigate to="/home" replace/>
   },
   {
      path: 'home',
      parentPath: '',
      name: 'Home Page',
      element:<Home/>,
      icon: <HomeIcon/>,
      lightIcon: <HomeIcon/>,
      visible: true,
      rbac: ["anonymous"],
      children: []
   }
   ]



export default function Routes() {

   return useRoutes(
      []
         .concat(homeList)
          // .concat(adminList)
          .concat([{ path: '*', element: <Navigate to="/home" replace /> }]) // Catch-all route
   );

}
